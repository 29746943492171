import React from 'react';
import { Link } from 'gatsby';
import Layout from '../../components/Layout';
import BackgroundImage from '../../components/BackgroundImage';
import Helmet from 'react-helmet'
import { ImageOverlay } from '../../utils';
import useSiteMetadata from '../../components/SiteMetadata';
import MetaComponent from '../../components/MetaComponent';

export const CareersPageTemplate = ({
  heroImageObject,
  title,
  jobs,
  smallTitle,
  seoObject,
  imageTitle,
  helmet,
}) => (
  <div className='page-container container content'>
    {helmet || ''}
    <Helmet titleTemplate={`%s | H2 Group`}>
      <title>{`${seoObject.metaTitle}`}</title>
      <meta name="description" content={`${seoObject.metaDescription}`} />
    </Helmet>
    <div className='small-title'>{smallTitle}</div>
    <h1 className='page-title'>{title}</h1>
    <BackgroundImage
      imageInfo={heroImageObject.image}
      classes='page-header-image'>
      <div className='image-title'>
        <hr />
        {imageTitle}
      </div>
      <ImageOverlay overlay={heroImageObject.overlay} />
    </BackgroundImage>
    <div className='container content'>
    <iframe class="bamboo-iframe" src="https://h2.bamboohr.com/jobs/embed2.php?version=1.0.0"></iframe>
      {/* <div className='columns is-variable is-6 is-multiline career-listings'>
        {!!jobs ? (
          jobs.map((job) => (
            <div className='column job-post is-6'>
              <Link to={job.node.fields.slug}>
                <div className='job-title'>{job.node.frontmatter.title}</div>
                <div className='job-description'>
                  {job.node.frontmatter.description}
                </div>
              </Link>
            </div>
          ))
        ) : (
          <h2>
            No jobs found, please try again later or contact us at
            info@h2group.com
          </h2>
        )}
      </div> */}
    </div>
  </div>
);

const CareersPage = ({ data, location }) => {
  const jobsList = data.allMarkdownRemark.edges;
  const pageData = data.markdownRemark.frontmatter;
  const { title, siteUrl } = useSiteMetadata();
  return (
    <Layout>
      <CareersPageTemplate
        location={location}
        heroImageObject={pageData.heroImageObject}
        title={pageData.propertiesTitle}
        smallTitle={pageData.propertiesSmallTitle}
        imageTitle={pageData.imageTitle}
        seoObject={pageData.seoObject}
        jobs={jobsList}
        helmet={
          <MetaComponent
            title={`${pageData.seoObject.metaTitle}`}
            canonical={`${siteUrl}${location.pathname}`}
            siteName={`${title}`}
            description={`${pageData.seoObject.metaDescription}`}
            link={`${siteUrl}${location.pathname}`}
            image={`${pageData.heroImageObject.image}`}
          />
        }
      />
    </Layout>
  );
};

export default CareersPage;

export const pageQuery = graphql`
  query CareersPageQueryZHHANS {
    markdownRemark(
      frontmatter: { templateKey: { eq: "careers-page-zhhans" } }
    ) {
      frontmatter {
        title
        smallTitle
        imageTitle
        heroImageObject {
          overlay
          image
        }
        seoObject {
          metaTitle
          metaDescription
        }
      }
    }
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "job-post-zhhans" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            description
          }
        }
      }
    }
  }
`;
